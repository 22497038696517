<!--
 * @Author: 张阳帅
 * @Date: 2024-09-25 17:52:37
 * @LastEditTime: 2024-11-21 14:41:05
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \szlsnk-user-mp\src\views\gjc\patient\patientEdit.vue
-->
<template>
  <div class="gcjregister_page">
    <div class="page_one">
      <div class="one_item">
        <div class="item_left">
          就诊人类型：
        </div>
        <div class="item_right"> 
          <van-radio-group v-model="jzrlx" direction="horizontal" disabled>
            <van-radio :name="'1'" checked-color="#1e80ff">
              成人
            </van-radio>
            <van-radio :name="'2'" checked-color="#1e80ff">
              儿童(小于14岁)
            </van-radio>
          </van-radio-group>
        </div>
      </div>
      <div class="one_item">
        <div class="item_left">
          姓名：
        </div>
        <div class="item_right"> 
          <input v-model="xm" class="right_name" placeholder="请输入姓名" maxlength="64" disabled>
        </div>
      </div>
      <div class="one_item">
        <div class="item_left">
          性别：
        </div>
        <div class="item_right"> 
          <van-radio-group v-model="xb" direction="horizontal" disabled>
            <van-radio name="1" checked-color="#1e80ff">
              男
            </van-radio>
            <van-radio name="2" checked-color="#1e80ff">
              女
            </van-radio>
          </van-radio-group>
        </div>
      </div>
      <div class="one_item">
        <div class="item_left">
          证件类型：
        </div>
        <div class="item_right">
          <!-- <div class="item_right" @click="seleteZjlxAction"> -->
          {{ zjlxName }}
          <!-- <van-icon name="arrow" size="23"></van-icon> -->
        </div>
      </div>
      <div class="one_item">
        <div class="item_left">
          证件号：
        </div>
        <div class="item_right">
          <input v-model="zjh" class="right_name" placeholder="请输入证件号" maxlength="18" disabled @blur="setBirthdayData">
        </div>
      </div>
      <div class="one_item">
        <div class="item_left">
          出生日期：
        </div>
        <div class="item_right">
          {{ csrq.length?csrq:'自动带入' }}
        </div>
      </div>
      <div class="one_item">
        <div class="item_left">
          手机号：
        </div>
        <div class="item_right">
          <input v-model="sjh" class="right_name" placeholder="请输入手机号" maxlength="11">
        </div>
      </div>
      <div class="one_item">
        <div class="item_left">
          与本人关系：
        </div>
        <!-- <div class="item_right" @click="seleteGxAction"> -->
        <div class="item_right">
          {{ gxName }}
          <!-- <van-icon name="arrow" size="23"></van-icon> -->
        </div>
      </div>
    </div>
    <div v-if="jzrlx=='2'" class="page_two">
      <div class="one_item">
        <div class="item_left">
          监护人姓名：
        </div>
        <div class="item_right">
          <input v-model="jhrxm" class="right_name" placeholder="请输入监护人姓名" maxlength="15" disabled>
        </div>
      </div>
      <div class="one_item">
        <div class="item_left">
          监护人证件类型：
        </div>
        <!-- <div class="item_right" @click="seleteJhrzjlxAction()"> -->
        <div class="item_right">
          {{ jhrzjlxName }}
          <!-- <van-icon name="arrow" size="23"></van-icon> -->
        </div>
      </div>
      <div class="one_item">
        <div class="item_left">
          监护人证件号：
        </div>
        <div class="item_right">
          <input v-model="jhrzjh" class="right_name" placeholder="请输入监护人证件号" maxlength="18" disabled>
        </div>
      </div>
      <div class="one_item">
        <div class="item_left">
          监护人手机号：
        </div>
        <div class="item_right">
          <input v-model="jhrsjh" class="right_name" placeholder="请输入监护人手机号" maxlength="11" disabled>
        </div>
      </div>
    </div>
    <div class="page_two_1">
      <div class="one_item">
        <div class="item_left">
          是否默认：
        </div>
        <div class="item_right">
          <van-switch v-model="sfmr" active-color="#1e80ff" inactive-color="#dcdee0" />
        </div>
      </div>
    </div>
    <div class="page_bottom">
      <div class="bottom_btn" @click="debounceUpdatePatientData">
        保存
      </div>
      <!-- <div class="three_des">温馨提示:在线支付需全自费支付诊查费用</div> -->
    </div>
    <van-popup v-model="zjlxShow" position="bottom">
      <van-picker title="请选择证件类型" show-toolbar :columns="zjlxColumns" @confirm="onConfirm" @cancel="onCancel" />
    </van-popup>
    <van-popup v-model="jhrzjlxShow" position="bottom">
      <van-picker title="请选择证件类型" show-toolbar :columns="zjlxColumns" @confirm="onJhrConfirm" @cancel="onJhrCancel" />
    </van-popup>
    <van-popup v-model="gxShow" position="bottom">
      <van-picker title="请选择与本人关系" show-toolbar :columns="gxColumns" @confirm="onGxConfirm" @cancel="onGxCancel" />
    </van-popup>
  </div>
</template>
<script>
import { Toast } from 'vant'
import { gjcModel } from '../../../api/gjcApi'
import { debounce } from '../../../utils/index'
import { getBirth } from '../../../utils/cache'

export default {
    data(){
        return{
            id:'', //就诊人id
            jzrlx: '1', //就诊人类型： 1成人 2儿童
            xm:'', //姓名
            xb: '1', //性别： 1男 2女
            zjlx:'01', //证件类型
            zjlxName:'居民身份证',//证件类型名称
            zjlxShow:false, //证件类型弹窗
            zjlxColumns: [{text:'居民身份证',id:'01'},{text:'居民户口簿',id:'02'},{text:'护照',id:'03'},{text:'军官证',id:'04'},{text:'驾驶证',id:'05'},{text:'港澳居民来往内地通行证',id:'06'},{text:'台湾居民来往内地通行证',id:'07'},{text:'其他有效证件',id:'99'}],
            zjh:'', //证件号
            csrq:'',//出生日期
            sjh:'', //手机号
            gx :'', //关系
            gxName:'请选择与本人关系',//与本人关系
            gxShow:false, //关系类型弹窗
            gxColumns: [{text:'本人',id:'1'},{text:'夫妻',id:'2'},{text:'父母',id:'3'},{text:'子女',id:'4'},{text:'其他',id:'99'}],
            sfmr: false, //是否默认 1是 0否
            jhrxm:'',//监护人姓名
            jhrzjlx:'01', //监护人证件类型
            jhrzjlxName:'居民身份证',//监护人证件类型名称
            jhrzjlxShow :false, //监护人证件类型弹窗
            jhrzjh:'', //监护人证件号
            jhrsjh:'', //监护人手机号
            jzkh:'',//就诊卡号
            jzklx:'',//就诊卡类型
            jzklxmc:'',//就诊卡类型名称
            hisBrxz:'', //his信息
            hisDaid:'' //his信息
        }
    },
    mounted() {
        this.id = this.$route.query.id
        this.getPatientData()
    },
    methods:{
        // 获取就诊人信息
        getPatientData(){
            gjcModel.getPatientDetailData(this.id).then((result) => {
                // console.log('获取就诊人信息成功',result)
                this.jzrlx = result.data.jzrlx
                this.xm = result.data.xm
                this.xb = result.data.xb
                this.zjlx = result.data.zjlx
                this.zjlxName = result.data.zjlx=='01'?'居民身份证':(result.data.zjlx == '02'?'居民户口簿':(result.data.zjlx == '03'?'护照':(result.data.zjlx == '04'?'军官证':(result.data.zjlx == '05'?'驾驶证':(result.data.zjlx == '06'?'港澳居民来往内地通行证':(result.data.zjlx == '07'?'台湾居民来往内地通行证':(result.data.zjlx == '99'?'其他有效证件':'')))))))
                this.zjh = result.data.zjh
                this.csrq = result.data.csrq
                this.sjh = result.data.sjh
                this.gx = result.data.gx
                this.gxName = result.data.gx == '1'?'本人':(result.data.gx == '2'?'夫妻':(result.data.gx == '3'?'父母':(result.data.gx == '4'?'子女':(result.data.gx == '99'?'其他':''))))
                this.sfmr = result.data.sfmr == '1'?true:false
                this.jhrxm = result.data.jhrxm
                this.jhrzjlx = result.data.jhrzjlx
                this.jhrzjlxName = result.data.jhrzjlx=='01'?'居民身份证':(result.data.jhrzjlx == '02'?'居民户口簿':(result.data.jhrzjlx == '03'?'护照':(result.data.jhrzjlx == '04'?'军官证':(result.data.jhrzjlx == '05'?'驾驶证':(result.data.jhrzjlx == '06'?'港澳居民来往内地通行证':(result.data.jhrzjlx == '07'?'台湾居民来往内地通行证':(result.data.jhrzjlx == '99'?'其他有效证件':'')))))))
                this.jhrzjh = result.data.jhrzjh
                this.jhrsjh = result.data.jhrsjh
                let patientCard = result.data.patientCard || []
                this.jzkh = patientCard[0].jzkh
                this.jzklx = patientCard[0].jzklx
                this.jzklxmc = patientCard[0].jzklxmc
                this.hisBrxz = patientCard[0].hisBrxz
                this.hisDaid = patientCard[0].hisDaid
            }).catch((err) => {
                console.log('获取就诊人信息失败',err)
            })
        },
        debounceUpdatePatientData:debounce( function() {
            this.updatePatientData()
        },500,true),
        //编辑就诊人
        updatePatientData() {
            if(!this.xm) {
                this.$toast('请输入姓名')
                return
            }
            if(!this.zjlx) {
                this.$toast('请选择证件类型')
                return
            }
            if(!this.zjh) {
                this.$toast('请输入证件号')
                return
            }
            if(!this.sjh) {
                this.$toast('请输入手机号')
                return
            }
            if(this.sjh.length!=11) {
                this.$toast('请输入正确的手机号')
                return
            }
            if(!this.gx) {
                this.$toast('请选择与本人关系')
                return
            }
            Toast.loading({
              mask: true,
              duration: 0,
              message: "加载中..."
            })
            const params = {
                id:this.id,
                jzrlx: this.jzrlx,
                xm: this.xm,
                xb: this.xb,
                zjlx:this.zjlx,
                zjh:this.zjh,
                csrq:this.csrq,
                sjh:this.sjh,
                gx:this.gx,
                sfmr:this.sfmr?'1':'0',
                jhrxm:this.jhrxm,
                jhrzjlx:this.jhrzjlx,
                jhrzjh:this.jhrzjh,
                jhrsjh:this.jhrsjh,
                jzkh: this.jzkh,
                jzklx: this.jzklx,
                hisBrxz: this.hisBrxz,
                hisDaid: this.hisDaid
            }
            gjcModel.getUpdatePatientData(params).then(() => {
                // console.log('编辑就诊人成功',result)
                Toast.clear()
                this.$toast('编辑就诊人成功')
                setTimeout(() => {
                    this.$router.back()
                }, 1500)
            }).catch((err) => {
              setTimeout(() => {
                   Toast.clear() 
                }, 1000) 
                console.log('编辑就诊人失败',err)
            })
        },
        seleteZjlxAction() {
            this.zjlxShow = true
        },
        onConfirm(value) {
            //this.$toast(`当前值：${value}, 当前索引：${index}`)
            this.zjlxName = value.text
            this.zjlx = value.id
            this.zjlxShow = false
        },
        onCancel() {
            this.zjlxShow = false
        },
        seleteGxAction() {
            this.gxShow = true
        },
        onGxConfirm(value) {
            this.gxName = value.text
            this.gx = value.id
            this.gxShow = false
        },
        onGxCancel() {
            this.gxShow = false
        },
        setBirthdayData() {
            if(this.zjlx == '01') {
                this.csrq = getBirth(this.zjh)
            }
        },
        seleteJhrzjlxAction(){
            this.jhrzjlxShow = true
        },
        onJhrConfirm(value) {
            this.jhrzjlxName = value.text
            this.jhrzjlx = value.id
            this.jhrzjlxShow = false
        },
        onJhrCancel() {
            this.jhrzjlxShow = false
        }
    }
}
</script>
<style scoped lang='less'>
.gcjregister_page{
    background-color: #f5f5f5;
    padding-bottom: calc(env(safe-area-inset-bottom) + 124px);
}
.page_one{
    background-color: white;
    border-bottom: 20px solid #f5f5f5;
}
.one_item{
    display: flex;
    justify-content: space-between;
    justify-items: center;
    padding: 20px 24px;
    border-bottom: 1px solid #e1e1e1;
    .item_left{
        font-size: 32px;
        color: #333333;

        .item_docName{
            font-weight: bold;
            .item_docTitle{
                margin-left: 24px;
                font-size: 24px;
                color: #666;
                font-weight: normal;
            }
        }
        .item_deptName{
            font-size: 24px;
            color: #999999;
        }
    }
    .item_right{
        display: flex;
        font-size: 32px;
        color: #333;
        .right_name{
            border: 0;
            text-align: right;
        }
    }
}
.page_two{
    background-color: white;
    border-bottom: 20px solid #f5f5f5;

    .item_pa{
        display: flex;
        font-size: 32px;
        color: #999999;
    }
}

.page_two_1{
    background-color: white;
    border-bottom: 2px solid #f5f5f5;

    .item_pa{
        display: flex;
        font-size: 32px;
        color: #999999;
    }
}
.page_bottom{
  position: fixed;
  background: #fff;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 12px 32px calc(env(safe-area-inset-bottom) + 12px);
    .bottom_btn{
      display: flex;
      justify-content: center;
      align-items: center;
      height: 86px;
      background-color: #1e80ff;
      border-radius: 43px;
      color: white;
      font-size: 32px;
      text-align: center;
    }

    .three_des{
        margin: 0 24px;
        text-align: center;
        font-size: 24px;
        color: #999;
    }
}
    
</style>
